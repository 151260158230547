

function LoginError() {
    return <>
        <h1>
            Login Error
        </h1>
    </>
}

export default LoginError