import React from "react"
import { AppHeader } from "@nike/eds/dist/components/AppHeader/AppHeader"
import { Avatar } from "@nike/eds/dist/components/Avatar/Avatar"
import { TabGroup } from "@nike/eds/dist/components/TabGroup/TabGroup"
import { Tab } from "@nike/eds/dist/components/Tab/Tab"
import NikeLogoImg from "../../nike-logo.svg"
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header() {
    const { authState } = useOktaAuth();
    const [activeTab, setActiveTab] = React.useState(window.location.pathname.substring(1));
    const navigate = useNavigate();

    const email = authState?.accessToken?.claims?.sub;
    const initials = (email && email.indexOf("@") !== -1) ? getInitialsFromEmail(email) : "  ";


    const NikeLogo = ( <img src={ NikeLogoImg } alt="Nike Logo" /> );
    const CommandBar = (
        <TabGroup id="header-tab-group" activeId={activeTab} name="lc-tab-group" onChange={onTabChange}>
            <Tab id="local-clearance">EiDR Batches</Tab>
            <Tab id="assists">Assists</Tab>
        </TabGroup>
    )
    return (
        <AppHeader logoSlot={NikeLogo} appName={"Local Clearance"} isDark={true} toolbarSlot={CommandBar} avatarSlot={(<Avatar initials={initials} />)}></AppHeader>
    )

    function onTabChange(e: React.ChangeEvent<HTMLInputElement>
        ) {
            setActiveTab(e.target.id);
            navigate(e.target.id)
    }
}


function getInitialsFromEmail(email: string) {
    const [first, last] = email.split("@")[0].split(".");
    return first[0] + last[0];
}


export default Header;