import {useEffect} from "react"

function MicroFrontend({host, microFrontendUiName}) {
    useEffect(() => {
        const scriptId = `micro-frontend-script-${microFrontendUiName}`;
        const renderMicroFrontend = () => {
            const renderFunctionName = `render${microFrontendUiName}`;
            const renderFunction = window[renderFunctionName];
            if (!renderFunction) {
                console.warn("Render function not found: ", renderFunctionName, window);
            }
            renderFunction(`${microFrontendUiName}-container`);
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return;
        }

        fetch(`${host}/asset-manifest.json`)
            .then((res) => res.json())
            .then((manifest) => {
                const script = document.createElement("script");
                script.id = scriptId;
                script.crossOrigin = "";
                script.src = `${host}${manifest.files["main.js"]}`;
                script.onload = () => {
                    renderMicroFrontend();
                };
                document.head.appendChild(script);

                const style = document.createElement("link");
                style.href = `${host}${manifest.files["main.css"]}`;
                style.rel = "stylesheet";
                document.head.appendChild(style);
            });
    }, [host, microFrontendUiName]);

    useEffect(() => {
        return () => {
            window[`unmount${microFrontendUiName}`] && window[`unmount${microFrontendUiName}`](`${microFrontendUiName}-container`);
        };
    }, [])

    return <main id={`${microFrontendUiName}-container`} />
}

export default MicroFrontend;