import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../header/Header'
import '@nike/eds/dist/index.css'
import './AuthorizedLayout.css'
import { useOktaAuth } from '@okta/okta-react'
import { AuthState, toRelativeUrl } from '@okta/okta-auth-js'


function AuthorizedLayout() {
    const { oktaAuth, authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    const isAuthorized = hasLocalClearanceUserGroup(authState);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authState) {
            return
        }

        if (!isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin)
            oktaAuth.setOriginalUri(originalUri)
            oktaAuth.signInWithRedirect()
        }
        else if(!isAuthorized) {
            navigate("/login-error", {replace: true})
        }

    }, [oktaAuth, authState, isAuthenticated, isAuthorized])

    return (
        <div className="container">
            <Header />
            <div className="main">
                <Outlet />
            </div>
        </div>
    )
}

function hasLocalClearanceUserGroup(authState: AuthState | null) {
    const localClearanceUserGroup = window.env.REACT_APP_GROUP_NAME || '';

    const groups = extractAuthGroups(authState)
    if(!groups) return false;
    if(Array.isArray(groups))
      return groups.includes(localClearanceUserGroup);
    else
      return groups === localClearanceUserGroup;
}

function extractAuthGroups(authState: AuthState | null) {
    return authState?.idToken?.claims?.groups
}

export default AuthorizedLayout
