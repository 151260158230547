import './App.css'
import AuthorizedLayout from './components/layout/AuthorizedLayout'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { Security, LoginCallback } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { oktaAuth } from './security/OktaConfig'
import LoginError from './pages/LoginError'
import {LocalClearanceOverview} from './pages/LocalClearanceOverview'
import {AssistsOverview} from './pages/AssistsOverview'


function AppInSecureContext() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<AuthorizedLayout />}>
                    <Route path="/local-clearance" element={<LocalClearanceOverview/>} />
                    <Route path="/assists" element={<AssistsOverview />}/>
                </Route>
                <Route path="/implicit/callback" element={<LoginCallback errorComponent={LoginError} />} />
                <Route path="/login-error" element={<LoginError/>}></Route>
            </Routes>
        </div>
    )
}

const App = () => {
    const navigate = useNavigate()
    const restoreOriginalUri = useCallback(
        (_oktaAuth: any, originalUri: string) => {
            navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
        },
        [navigate]
    )

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <AppInSecureContext />
        </Security>
    );
}


export default App
