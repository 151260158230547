import { OktaAuth } from '@okta/okta-auth-js'

const oktaConfig = {
    issuer: window.env.REACT_APP_OKTA_ISSUER || '',
    redirectUri: `${window.location.origin}/implicit/callback`,
    clientId: window.env.REACT_APP_OKTA_CLIENT_ID || '',
    scopes: ['openid', 'groups'],
    pkce: true,
}

export const oktaAuth = new OktaAuth(oktaConfig)
